import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import * as variables from '../utilities/style-variables.js'

const Wrapper = styled.div`
  margin: 0 auto 100px;
  padding: 60px 40px 40px 40px;
  max-width: 450px;
  border-radius: ${variables.border_radius};
  background-color: ${variables.color_brand_bg_dark};
  color: ${variables.color_white};

  &:first-child{
      margin-top: 80px;

      @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
          margin-top: 50px;
      } 
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 60px;
`


const Icon = styled.div`
  flex: 0 1 auto;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: flex-start;
  width: 50px;
  height: 50px;
  padding: 15px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
`

const Heading = styled.h3 `
flex: 0 1 auto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin-bottom: 20px;
  font-size: ${variables.font_size__mobile_small};
  text-align: left;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_small};
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_small};
  }
`

const Text = styled.p`
  font-size: ${variables.font_size__mobile_text};

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    font-size: ${variables.font_size__tablet_text};
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    font-size: ${variables.font_size__desktop_text};
  }
`

const Card = ({ icon, heading, text }) => {

  const bgIcon = icon;

  return (
    <>
      <Wrapper className='container'>
        <Row className='row'>
          <Icon
            style={{backgroundImage: `url(${bgIcon})`}}
          >
          </Icon>
          <Heading>{heading}</Heading>
        </Row>
        <Text>{text}</Text>
      </Wrapper>
    </>
  )
}

Card.propTypes = {
  icon: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
}

Card.defaultProps = {
  heading: "",
  text: "",
}

export default Card
