import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"

import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';

const Ul = styled.ul `
    margin: 0 0 50px 0;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
      margin: 0 50px 50px 0;
      column-count: ${props => props.columnCount ? props.columnCount : "auto"};
    } 
`

const ListItem = styled.li `
    margin-bottom: 20px;
    padding: 0 0 0 10px;
    z-index: 1;

    &::before {
        content: "";
        display: inline-block;
        width: 3px;
        background-color: ${variables.color_black};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 5px 5px 5px 10px;
    } 
`

const StyledLink = styled(props => <Link {...props} />) `
    padding: 0 0 0 10px;
    position: relative;
    font-size: ${variables.font_size__mobile_text};
    z-index: 0;

    &::after {
        content: "";
        display: inline-block;
        width: 0px;
        background-color: ${variables.color_brand_bg};
        border-top-right-radius: ${variables.border_radius};
        border-bottom-right-radius: ${variables.border_radius};
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -10px;
        z-index: -1;
        transition: all ease-in-out 300ms;
    }

    &:hover {
        &::after {
            width: 110%;
            transition: all ease-in-out 300ms;
        }
    }
    
    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 5px 5px 5px 10px;
    } 
`


const List = ({ listItems, columnCount, inverted }) => {
  if (listItems) {
    return (
      <Ul as="ul" columnCount={columnCount}>
        {listItems.map ((item) => 
          <ListItem key={item.key.toString()}>
            <StyledLink to={item.href} inverted={inverted}>{item.text}</StyledLink>
          </ListItem>
        )}
      </Ul>
    )
  }
}

List.propTypes = {
    listItems: PropTypes.array,
}

List.defaultProps = {
    listItems: [],
}

export default List
