import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Btn from "../components/button"
import List from "../components/list"
import Section from "../components/section"
import ContentBlock from "../components/contentBlock"
import ContactForm from "../components/contactForm"
// import Quotes from "../components/quotes"
import Card from "../components/card"
import { StaticImage } from "gatsby-plugin-image"
import fbIcon from '../images/icons/plattforms/fb_logo_white_130x130.png'
import instaIcon from '../images/icons/plattforms/Insta_logo_white_130x130.png'
import tikTokIcon from '../images/icons/plattforms/TikTok_logo_white_300x300.png' 
import HeroBg from "../components/heroBg"
import bgImage from "../images/backgrounds/hero-website-home-2.jpg"

const IndexPage = ({ location }) => (

  <>
  {/* Hero section  */}
  <Layout>
    <Seo 
      title="Agentur für Social Media Marketing, Videoproduktionen und Animationen | showcase21"
      description="showcase21 Home - Social Media Marketing, Videoproduktionen und Animationen. Begeistere deine Community mit eindrucksvollen Videos und generiere loyale Kunden, die dein Unternehmenswachstum auf das nächste Level heben."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section heroSection>
      <div className="row">
        <div className="col-md-8">
          <h1 className="small">Agentur für Social Media Marketing | Videoproduktionen | Animationen</h1>
          <h2 className="main-heading">Begeistere deine Community.<br></br>Visuell und eindrucksvoll.</h2>
          {/* TODO: Link oragnische Videos & Anzeigen anpassen */}
          <List 
            listItems={[
              { text:"Vorteile von Social Media Videos", href:"/#success", key:"1" },
              { text:"Reels, Tiktoks Stories & Co.", href:"/#formate", key:"2" },
              { text:"Organische Videos & Anzeigen", href:"/#organisch-bezahlt", key:"3" },
              // { text:"Referenzen", href:"/#references", key:"4" },
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 d-flex justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Kontakt aufnehmen"
            href="#contact-form"
          />
        </div>
      </div>
    </Section>
    <HeroBg 
      bgImage={bgImage}
    />

    <Section>
      <div className="row" id="success">
        <div className="col">
          <div className="headings">
            <h2>Feiere deine Erfolge auf Social Media.<br></br>Mit überzeugten und loyalen Nutzern.</h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/image_home_filming_brand_product.JPG" 
            alt="Film Session um deine Marke und Produkte zu emotionaliseren" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Emotionalisiere deine Marke & werde bekannt."
            text={<>Die meisten Menschen geben ihr Geld bevorzugt dann aus, wenn sie ein gutes Gefühl haben. Und wer kauft schon gerne bei einem Unbekannten? Baue deine Marke mit visuellen Inhalten emotional auf und erreiche neue Interessenten. Ein weiterer Vorteil: Je stärker die Markenposition, desto billiger die vertrieblichen Maßnahmen.</>}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6">
          <StaticImage 
            src="../images/graphics/image_home_man_surfing_online_smartphone.jpg" 
            alt="Erreiche neue Menschen mit deiner Marke und Produkt" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Erreiche neue Menschen und überzeuge sie von dir."
            text={<>Bewegtbild springt ins Auge und wenn man schon die Aufmerksamkeit der Nutzer gewonnen hat, ist das der richtige Augenblick um sein Produkt, seine Dienstleistung oder Marke vorzustellen. Egal ob Animationen, Reels, Stories oder Erklärvideos. .</>}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/graphics/image_home_girl_filming_product_review.jpg" 
            alt="Mache deine Marke zu einem Erlebnis." 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Hören UND sehen bedeutet erleben."
            text={<>Erfolgreiche Videos, werden auf sozialen Plattformen wie Facebook, Instagram oder TikTok täglich tausendfach geteilt. Sie können sowohl informativ sein als auch nur Unterhaltung der Zielgruppe als Ziel haben. Eins haben sie jedoch immer gemeinsam - sie sind ein Erlebnis.</>}
          />
        </div>
      </div>
    </Section>

    <Section>
      <div className="row" id="organisch-bezahlt">
        <div className="col">
          <div className="headings">
            <h2>Mit organischen oder bezahlten Videoformaten ans Ziel.</h2>
            <p className="subheading">Entfalte das volle Potenzial, in dem du dir die einzelnen Formate auf den Social Media Portalen zu nutze machst.</p>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={instaIcon}
            heading="Instagram Reels & Stories"
            text="Mit Instagram Reels und Stories bietet das Meta Universum Videoformate mit einer hohen Reichweite an.
            Der Vorteil sind die Individualisierungs-möglichkeiten des Contents. Binde Nutzer durch interaktive Sticker noch mehr an deine Marke oder Produkt
            und interagiere direkt mit ihnen."      
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={tikTokIcon}
            heading="TikToks"
            text="TikTok ist das am schnellstwachsende soziales Netzwerk der Welt, welches durch seine authentischen, kurzen Videos berühmt wurde. 
            Mittlerweile sind Videos mit bis zu 3 Minuten länge möglich. 
            Die größte Zielgruppe macht dabei die Altersgruppe 25 - 49 mit über 60% aus."
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <Card 
            icon={fbIcon}
            heading="Facebook Videos"
            text="Facebook bietet als etabliertes Social Network viele Videofunktionen. 
            Nicht zu vernachlässigen sind Gruppen, Seiten und andere Funktionen, durch die Facebook Nutzer mit deinem Unternehmen in Kontakt treten können. Und wie wäre es mit einer Werbeanzeige?"       
          />
        </div>
      </div>
    </Section>

    {/* <Section bgLight>
      <div className="row" id="references">
        <div className="col">
          <div className="headings">
            <h2>Erfolge</h2>
            <p className="subheading">… die bereits gefeiert wurden.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <Quotes />
      </div>
    </Section> */}

    <Section >
      <div className="row">
        <div className="col">
          <div className="headings">
            <h2>Kontakt</h2>
            <p className="subheading">Schicke mir eine Nachricht mit deinem Anliegen oder deinen Fragen.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContactForm />
        </div>
      </div>
    </Section>
    
  </Layout>
  </>
)
export default IndexPage
